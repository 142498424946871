import $ from "jquery";
import Cookies from "js-cookie";
import {delay} from "lodash";
import tinymce from "tinymce";

$("#messages .message .close").on("click", function () {
  $(this).closest(".message").transition("fade");
});

// prevent multiple form submissions
delay(() => {
  $("form.disable-on-submit").submit(function (e) {
    if (!e.isDefaultPrevented()) {
      $(this).addClass("loading");
    }
  });
});

if (!$(".messages .message:not(.hidden)")) {
  $(".messages .divider").hide();
}

let $sideMenu = $("#side-menu");

if ($sideMenu.length && $(".show.menu.item").length) {
  $sideMenu.sidebar("attach events", ".show.menu.item");
}

// ajax setup
const csrftoken = Cookies.get("csrftoken");

const csrfSafeMethod = (method) => {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
};

$.ajaxSetup({
  beforeSend(xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
  },
});

// Follow button
$("form.follow-form").submit(function (e) {
  e.preventDefault();
  $(this).find("button").addClass("loading");
  $.post($(this).attr("action"), () => {
    $(this).find("button").removeClass("loading");
    $(this).find("button").toggle();
  }).fail(() => {
    $(this).find("button").removeClass("loading");
  });
});

// Auto select: select all on selection (good for copy and paste).
$(".auto-select").click(function () {
  if (document.selection) {
    let range = document.body.createTextRange();
    range.moveToElementText(this.firstChild);
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(this.firstChild);
    window.getSelection().addRange(range);
  }
});

//TinyMCE
tinymce.init({
  selector: ".richtextarea",
  browser_spellcheck: true,
  menubar: false,
  nonbreaking_force_tab: true,
  paste_merge_formats: true,
  plugins: "advlist lists paste code legacyoutput fullscreen nonbreaking hr table textcolor",
  toolbar_items_size: "small",
  toolbar:
    "undo redo | bold italic underline forecolor | subscript superscript | bullist numlist outdent indent | table | hr | pastetext code fullscreen",
  valid_elements: "p,br,b/strong,i,u,sup,sub,ol[style],ul[style],li,font[color],hr,table,tr,td,img[src]",
  content_css: "/static/dist/tinymce.css",
  setup(ed) {
    if ($(`#${ed.id}`).prop("readonly")) {
      ed.settings.readonly = true;
    }
  },
});
