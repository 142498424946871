import {isString, isUndefined} from "lodash";

export const getQueryParams = () => {
  let hashes;
  let hash;
  let vars = {};
  let href = window.location.href;

  if (!href.includes("?")) return vars;

  hashes = href.slice(href.indexOf("?") + 1, href.includes("#") ? href.indexOf("#") : href.length).split("&");

  hashes.forEach((chunk) => {
    hash = chunk.split("=");
    if (!hash[0] && !hash[1]) {
      return;
    }

    vars[decodeURIComponent(hash[0])] = hash[1] ? decodeURIComponent(hash[1]).replace(/\+/, " ") : "";
  });

  return vars;
};

export const sortArray = (arr, score_fn) => {
  arr.sort((a, b) => {
    let a_score = score_fn(a);
    let b_score = score_fn(b);

    for (let i = 0; i < a_score.length; i++) {
      if (a_score[i] > b_score[i]) {
        return 1;
      }
      if (a_score[i] < b_score[i]) {
        return -1;
      }
    }
    return 0;
  });

  return arr;
};

export const objectIsEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return true;
};

export const trim = (str) => str.replace(/^\s+|\s+$/g, "");

export const defined = (item) => !isUndefined(item) && item !== null;

export const nl2br = (str) => str.replace(/(?:\r\n|\r|\n)/g, "<br />");

export const isUrl = (str) => !!str && isString(str) && (str.indexOf("http://") === 0 || str.indexOf("https://") === 0);

export const escape = (str) => str.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");

export const percent = (value, totalValue, precise) => (value / totalValue) * 100;

export const toTitleCase = (str) =>
  str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

export const getCookie = (cname) => {
  let name = `${cname}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
