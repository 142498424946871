import $ from "jquery";

export const blockUI = (options) => {
  const defaultOptions = {
    size: "massive",
    text: "",
  };
  options = $.extend(defaultOptions, options);
  const $loader = $(".page.dimmer .loader");
  $loader.addClass(options.size);
  $loader.html(options.text);
  $(".page.dimmer").dimmer("show");
};

export const unBlockUI = () => {
  $(".page.dimmer").dimmer("hide");
  const $loader = $(".body.dimmer .loader");
  $loader.html("");
  $loader.removeClass("mini tiny small medium large big huge massive");
};
