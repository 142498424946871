import * as Sentry from "@sentry/browser";
import autosize from "autosize";
import Immutable from "immutable";
import _ from "lodash";
import moment from "moment";
import React from "react";

window._ = _;
window.React = React;
window.Immutable = Immutable;
window.autosize = autosize;
window.moment = moment;
window.Sentry = Sentry;

const Sentinel = {
  ui: require("./ui"),
  utils: require("./utils"),
};

window.Sentinel = Sentinel;

require("./main");
